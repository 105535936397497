import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/IR_LEDs/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import IRTable from 'components/Web_User_Interface/1440p_Series/Features/IR_LEDs/irTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Infrared Nightvision",
  "path": "/Web_User_Interface/1440p_Series/Features/IR_LEDs/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window or you are using your camera e.g. as a weather webcam and don´t need the IR light, simply deactivate the LEDs here.",
  "image": "./WebUI_1440p_SearchThumb_Features_Nightvision.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_Nightvision.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Infrared Nightvision' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window or you are using your camera e.g. as a weather webcam and don´t need the IR light, simply deactivate the LEDs here.' image='/images/Search/WebUI_1440p_SearchThumb_Features_Nightvision.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_Nightvision.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/IR_Nachtsicht/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/IR_LEDs/' crumbLabel="Infrared Nightvision" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ccddaae993a4c7013f05a3aefda83d0/2e367/1440p_Settings_Features_Nightvision.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7rAAAO6wFxzYGVAAACWElEQVQoz12TzU8TQRiH+z8YPRS1KU0baAuBgNF0+0FbCv3Ybbu73Z3ttpTEBIgFQhQKIaAVo2BMvJroTU+KZzQx6MGTB05e/WMes621H4dfZnYz88yz+77jmp6eJhwOEw5PMeH3ErMOKLa/kN3/SGbvnExrOIutzyzvn2OdfiMqWsSlu4TCU7jdbjweD65gMIiTUCiE3zdOutxgZe819s4pYvsFleaz4Wwcoa8dYm+fkDPWiNyLcGf+HjMzc8zOzuNyQL34/QEW4hKWXkQv5VGVLKq83B+LBbT1YypbZ6gbbfI5mVQ8Sy6nsZBYJCotjBj6/SSTKer1FSqGia5X0DQdVXNGDdWw0I8+UX15SfX0ArVUR0/baHKDdDJFLJbANTk52YE50C4wiW3bCCEwDANVVTuwblR0tURFLVGzBUI1ORINWisGpVKKWDTeBfYse8BqtdqB9YCDcd5VKhUMUyDLWQ4fmDw/UNFKMaKRAaBjGQgEOsBarfbf0Nns2Om63gX9O8hZs9lc5+ePr3x4tUshI/UNnbYZNRwEOPPBZ9M0kWWZp+1j/lz94urtG1aVDJIkDRv2gI1Go2PobHTizIVlIYSFaQpsy6KQz3P46CG/Ly/4/v4d7VWFRCLWr/Ko4aBduaxSFQY1oWNZJiXLYqmoUMjnkJfSZDNJInMhQsGJ0T7sV7n3mU40tYy52kQ0H1O/v8OuYXJs2iSjUa5dv4F7bIybtzzDN6VnGI/HKZfLnX+kKAqKUkSR8xRrmyjNM4S9xZPUIidOU0sStz0efD4f414vXq+Xv/snxshBS7HkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ccddaae993a4c7013f05a3aefda83d0/e4706/1440p_Settings_Features_Nightvision.avif 230w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/d1af7/1440p_Settings_Features_Nightvision.avif 460w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/7f308/1440p_Settings_Features_Nightvision.avif 920w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/730d0/1440p_Settings_Features_Nightvision.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ccddaae993a4c7013f05a3aefda83d0/a0b58/1440p_Settings_Features_Nightvision.webp 230w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/bc10c/1440p_Settings_Features_Nightvision.webp 460w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/966d8/1440p_Settings_Features_Nightvision.webp 920w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/fbb05/1440p_Settings_Features_Nightvision.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ccddaae993a4c7013f05a3aefda83d0/81c8e/1440p_Settings_Features_Nightvision.png 230w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/08a84/1440p_Settings_Features_Nightvision.png 460w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/c0255/1440p_Settings_Features_Nightvision.png 920w", "/en/static/2ccddaae993a4c7013f05a3aefda83d0/2e367/1440p_Settings_Features_Nightvision.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ccddaae993a4c7013f05a3aefda83d0/c0255/1440p_Settings_Features_Nightvision.png",
              "alt": "Web User Interface - 1440p Series - Features IR LEDs",
              "title": "Web User Interface - 1440p Series - Features IR LEDs",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IRTable mdxType="IRTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "please-be-aware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#please-be-aware",
        "aria-label": "please be aware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Please be aware`}</h3>
    <p>{`If you deactivate the IR LEDs by night you might get false alerts from the internal motion detection. In this case, you will have to deactivate the motion detection during the night using the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Schedule/"
      }}>{`Alarm Schedule`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`couple the internal motion detection with the integrated PIR sensor`}</a>{`.`}</p>
    <h3 {...{
      "id": "additional-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#additional-settings",
        "aria-label": "additional settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Settings`}</h3>
    <p>{`Cameras with multiple LED sets come with additional settings. For example the model IN-8401 2k+ WQHD comes with "invisible" (940nm) night vision IR LEDs and a set of 850nm IR LEDs - that are stronger but can be seen when active as they emit a light red sheen. For this reason you might want to use them separately in different situation:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/516d2b5770a2be8ea15e68e83603c879/2e367/1440p_Settings_Features_Nightvision_8401.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAChUlEQVQoz12TzU8TQRiH+SPUA2qaSkqEFoJRI+kXbbFlS7vdj5nZ3dLSmggSqB8IFMSPICoYjTHRA0Q96cnoWU2MXDx58GLizT/mMbu1ETj88k4meZ88885Mz/DwMLFYjFhsiNORMClvjcrGZ7TV9+RXPpBvH8zF9kcmVj/gbX8l6bZJJy4QjQ3R29tLKBSiZ3BwED/RaJRI3ynGjQaNlefUFh9Tvb6Ns/AAp9WJaj1Azt1FXrlN9eomRTVLfDTO+XOjjIyc5cyZc/T4oG4ikX4yqQSeqCCNSYpFjcKERkHrVE2bwJq9g7r2BHF1m8lJg1xao1gUZMYukkxkDhlGImSzOWq1OpZQrFw22WqZbM771eLWjIHY2mNq9w/y5W8sMYPIedjlJuPZHKnUGD0DAwMBzId2gFlqtRq2VDxcMNldKvHiRomdm2W25svYs+uo1iOmV57hqSZ3nDrtaQfDyJFKpjvArmUXWK1WcV0X3XbIlwWFsk2+ZKPpNso2kGYZV5jopQnW5x0erVnYRopkfB/Qt+zv7yeTyQRAx3FwlEQKGymsoArbRkiJVA7KcWktzPF97wvvni5Tyif+G/rP5rChUgrpN0uJ7YOECNb+vqMUthBs3r/Hn58/+Plqh6aeJ5FIHDTcP8Og0bc8HNfF8zz0SoXby0v8+vaJvbev2WjqjI2l/t/yfsN6vR40dw39+FaGUcEyTZSUwdH1colSYRwtnyV+Nkp08PThd9gBTk1NBUDlz0opLMukVp+m0bhEvXkJrzFN1fVIJpMcOXqM3uPHOXEydPCndA3T6XQwM13X/6WCXipSaSxitt/gzW7wpKCxUzQoxhOcCIXo6+vjVDhMOBzmL8ZmymqhEhpdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/516d2b5770a2be8ea15e68e83603c879/e4706/1440p_Settings_Features_Nightvision_8401.avif 230w", "/en/static/516d2b5770a2be8ea15e68e83603c879/d1af7/1440p_Settings_Features_Nightvision_8401.avif 460w", "/en/static/516d2b5770a2be8ea15e68e83603c879/7f308/1440p_Settings_Features_Nightvision_8401.avif 920w", "/en/static/516d2b5770a2be8ea15e68e83603c879/730d0/1440p_Settings_Features_Nightvision_8401.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/516d2b5770a2be8ea15e68e83603c879/a0b58/1440p_Settings_Features_Nightvision_8401.webp 230w", "/en/static/516d2b5770a2be8ea15e68e83603c879/bc10c/1440p_Settings_Features_Nightvision_8401.webp 460w", "/en/static/516d2b5770a2be8ea15e68e83603c879/966d8/1440p_Settings_Features_Nightvision_8401.webp 920w", "/en/static/516d2b5770a2be8ea15e68e83603c879/fbb05/1440p_Settings_Features_Nightvision_8401.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/516d2b5770a2be8ea15e68e83603c879/81c8e/1440p_Settings_Features_Nightvision_8401.png 230w", "/en/static/516d2b5770a2be8ea15e68e83603c879/08a84/1440p_Settings_Features_Nightvision_8401.png 460w", "/en/static/516d2b5770a2be8ea15e68e83603c879/c0255/1440p_Settings_Features_Nightvision_8401.png 920w", "/en/static/516d2b5770a2be8ea15e68e83603c879/2e367/1440p_Settings_Features_Nightvision_8401.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/516d2b5770a2be8ea15e68e83603c879/c0255/1440p_Settings_Features_Nightvision_8401.png",
              "alt": "Web User Interface - 1440p Series - Features IR LEDs",
              "title": "Web User Interface - 1440p Series - Features IR LEDs",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      